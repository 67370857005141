<template>
	<div class="row chartArea" :style="{ justifyContent: 'start' }">
		<div class="col-xl-12 col-lg-12 innerBox" :style="{ padding: '5px' }">
			<div v-for="(data, i) in performerChartData" :key="`performerChart${i}`" class="col-xl-12 mixChart" :style="{ marginBottom: '5px', marginTop: '5px' }">
				<mix-chart ref="chart" v-if="data.flag" :chartData="data.data" :options="performerChartOptions[i]" :style="{ height: '100%', padding: '15px' }" />
			</div>
		</div>
	</div>
</template>

<script>
	import AnalysisLib from "@src/views/analysis/AnalysisLib";
import MixChart from "../../../components/vue-chartjs/Charts/MixChart.js";

	export default {
		name: "CostChartComponent",
		props: ["dataList", "propList"],
		components: {
			MixChart,
		},
		data() {
			return {
				performerChartData: [],
				performerChartOptions: [],

				environmentalFactorOptions: [
					{ text: "외기온도", value: "outTemp" },
					{ text: "실내온도", value: "inTemp" },
				],
			};
		},
    computed: {
      locale(){
        return this.$store.state.locale;
      }
    },
		watch: {
			dataList() {
				this.reset();
				this.init();
			},
		},
		created() {
			this.init();
		},
		mounted() {},
		updated() {},
		methods: {
			init() {
				this.defaultData();

				this.performerChartData.forEach((item) => {
					item.flag = true;
				});

				this.setOptions();
				this.setLabels();
				this.setDatasets();
			},
			reset() {
				this.performerChartData = [];
				this.performerChartOptions = [];
			},
			defaultData() {
				for (let i = 0; i < this.propList.length; i++) {
					this.performerChartData.push({
						key: this.propList[i].equipPropCode,
						flag: false,
						data: {
							labels: null,
							datasets: null,
						},
						options: null,
					});
				}
			},
			setOptions() {
				this.setMixChartOptions();
			},
			setMixChartOptions() {
				for (let i = 0; i < this.propList.length; i++) {
					this.performerChartOptions.push({
						responsive: true,
						maintainAspectRatio: false,
						animation: {
							duration: 1000,
							easing: "easeOutQuart",
						},
						hoverMode: "index",
						legend: {
							display: false,
							align: "end",
							labels: {
								usePointStyle: true,
							},
						},
						title: {
							display: true,
							align: "start",
							position: "top",
							text: `${this.propList[i].equipPropName} (${this.propList[i].unitSmallCodeName})`,
							fontSize: 20,
							fontColor: "#000000",
						},
						layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
						hover: {
							intersect: false,
						},
						scales: {
							xAxes: [
								{
									id: "x-axis-0",
									stacked: false,
                  offset: true,
									ticks: {
										autoSkip: true,
										fontColor: "#000000",
										fontSize: 11,
                    // labelOffset: 30
										// maxTicksLimit: 10,
										maxRotation: 0,
									},
								},
							],
							yAxes: [
								{
									id: "Left_yAxis",
									display: true,
									position: "left",
									stacked: false,
									ticks: {
										maxTicksLimit: 10,
										beginAtZero: true,
										fontColor: "#000000",
										fontSize: 12,
									},
								},
							],
						},
					});
				}
			},
			setLabels() {
				this.setMixChartLabels();
			},
			setMixChartLabels() {
				this.defaultMixChartLabels();
        console.log(this.dataList, this.propList);
				this.dataList.forEach((data) => {
					this.performerChartData.forEach((item) => {
            // console.log(data);
						item.data.labels.push(AnalysisLib.formatDatetimeForEquipPerform(data.chartRegDtTag));
					});
				});

				// this.apiData.chartData.forEach((data) => {
				//   this.mixChartData.data.labels.push(data.regDtTag);
				// });

				// console.log(this.mixChartData.data.labels);
			},
			resetMixChartLabels() {
				this.performerChartData.forEach((item) => {
					item.data.labels = null;
				});

				// this.mixChartData.data.labels = null;
			},
			defaultMixChartLabels() {
				for (let i = 0; i < this.propList.length; i++) {
					this.performerChartData[i].data.labels = [];
				}
			},

			setDatasets() {
				this.setMixChartDatasets();
			},
			setMixChartDatasets() {
				this.defaultMixChartDatasets();

				let tmpData = {
					key: null,
					type: "line",
					label: [],
					data: [],
					backgroundColor: "rgba(255, 99, 132, 0.2)",
					borderColor: "rgba(255, 99, 132, 1)",
					borderWidth: 1,
					fill: false,
				};

				for (let j = 0; j < this.performerChartData.length; j++) {
					tmpData.key = this.performerChartData[j].key;
					tmpData.label = this.performerChartData[j].key;

					//수정 권철규 차트 데이터 Set 2022-09-28
					for (let i = 0; i < this.dataList.length; i++) {
						let data = this.dataList[i];
						if (data.MODE == "-") {
							tmpData.data.push(null);
						} else {
							// tmpData.data.push(Number(data[tmpData.key].replaceAll(",", "")));
							tmpData.data.push(data[tmpData.key]);
						}
					}

					this.performerChartData[j].data.datasets.push(tmpData);

					tmpData = {
						key: null,
						type: "line",
						label: [],
						data: [],
						backgroundColor: "rgba(255, 99, 132, 0.2)",
						borderColor: "rgba(255, 99, 132, 1)",
						borderWidth: 1,
						fill: false,
					};
				}
			},
			setMixChartColor(datasets, key, divCd) {
				if (key == "base") {
					datasets.backgroundColor = this.cardColor[divCd];
					datasets.borderColor = this.cardColor[divCd];
				} else {
					datasets.backgroundColor = this.cardColor[divCd] + "aa";
					datasets.borderColor = this.cardColor[divCd] + "aa";
				}
			},
			resetMixChartDatasets() {
				this.mixChartData.data.datasets = null;
			},
			defaultMixChartDatasets() {
				this.performerChartData.forEach((item) => {
					item.data.datasets = [];
				});

				// this.mixChartData.data.datasets = [];
			},
			setMixChartDatasetsToData(datasets, key, divCd) {
				this.apiData.chartData.forEach((data) => {
					if (data[key + "Val" + divCd] == "-") {
						datasets.data.push(null);
					} else {
						datasets.data.push(data[key + "Val" + divCd]);
					}
				});
			},

			resetEnvFaCheckbox() {
				this.$refs.envFaCheckbox0[0].checked = false;
				this.$refs.envFaCheckbox1[0].checked = false;
			},
			onChangeEnvironmentalFactor(value, checked, id) {
				if (checked) {
					let result = [];

					this.apiData.environmentalFactorData.forEach((data) => {
						result.push(data[value + "Value"]);
					});

					this.addEnvFactorData(result, id);
				} else {
					this.delEnvFactorData(id);
				}
			},
			addEnvFactorData(result, id) {
				this.mixChartData.data.datasets.push({
					id: id,
					type: "line",
					label: "test" + id,
					xAxisID: "x-axis-0",
					yAxisID: "Right_yAxis",
					order: 1,
					data: result,
					borderColor: this.cardColor.env[id],
					fill: false,
					pointBorderColor: this.cardColor.env[id],
					pointBackgroundColor: "#fff",
				});
				return this.mixChartData.data.datasets.length - 1;
			},
			delEnvFactorData(id) {
				this.mixChartData.data.datasets.forEach((data, index) => {
					if (data.id == id) {
						this.mixChartData.data.datasets.splice(index, 1);
						return;
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.innerBox {
		padding: 5px;
	}

	.mixChart {
		background-color: #ffffff;
		height: 270px;
	}

	.drawLine {
		background-color: #ffffff;
		height: 350px;
	}

	.inputGroup {
		background-color: #fff;
		display: block;
		margin: 10px 0;
		position: relative;

		.custom-control {
			background-color: #5562eb;
		}

		label {
			padding: 12px 7%;
			font-size: 16px;
			width: 100%;
			display: block;
			text-align: left;
			color: #5a5a5a;
			cursor: pointer;
			position: relative;
			z-index: 2;
			transition: color 200ms ease-in;
			overflow: hidden;

			&:before {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				content: "";
				background-color: #b9b8b8;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) scale3d(1, 1, 1);
				transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
				opacity: 0;
				z-index: -1;
			}

			&:after {
				width: 32px;
				height: 32px;
				content: "";
				border: 2px solid #d1d7dc;
				background-color: #fff;
				background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ");
				background-repeat: no-repeat;
				background-position: 2px 3px;
				border-radius: 50%;
				z-index: 2;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translateY(-50%);
				cursor: pointer;
				transition: all 200ms ease-in;
			}
		}

		input:checked ~ label {
			color: #fff;

			&:before {
				transform: translate(-50%, -50%) scale3d(56, 56, 1);
				opacity: 1;
			}

			&:after {
				background-color: #616161;
				border-color: #616161;
			}
		}

		input {
			width: 32px;
			height: 32px;
			order: 1;
			z-index: 2;
			position: absolute;
			right: 30px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
			visibility: hidden;
		}
	}

	// codepen formatting
	.form {
		padding: 0 16px;
		max-width: 550px;
		margin: 50px auto;
		font-size: 18px;
		font-weight: 600;
		line-height: 36px;
	}

	body {
		background-color: #d1d7dc;
		font-family: "Fira Sans", sans-serif;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}

	html {
		box-sizing: border-box;
	}

	code {
		background-color: #9aa3ac;
		padding: 0 8px;
	}
</style>
