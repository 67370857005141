<template>
	<div class="EquipPerformanceComponent">
		<equip-performance ref="equipPerformance" :systemTypeCode="systemTypeCode" :name="name"></equip-performance>
	</div>
</template>

<script>
	import equipPerformance from "./EquipPerformance.vue";

	export default {
		name: "EquipPerformanceComponent",
		data() {
			return {
				systemTypeCode: this.$route.query.systemCode ?? null,
				name: this.$route.matched[0].props.default.name,
			};
		},

		watch: {
			async $route(from) {

				this.systemTypeCode = await from.query.systemCode ?? null;
				this.name = await from.matched[0].props.default.name;
				await this.$refs.equipPerformance.$refs.equipPerformanceSearch.setDefaultValue();
				this.$refs.equipPerformance.$refs.equipPerformanceSearch.defaultSearch();
				// this.setDefaultValue();
				// this.defaultSearch();
			},
		},
		components: {
			equipPerformance,
		},
	};
</script>

<style></style>
