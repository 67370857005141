<template>
	<div>
		<div class="text-right">
			<b-button type="button" class="mb-1 btn-light" @click="onExcelDownload()">{{ $t("다운로드") }}</b-button>
		</div>
		<b-table
			hover
			bordered
			head-variant="light"
			table-variant="light"
			no-border-collapse
			fixed
			show-empty
			sticky-header="355px"
			ref="analysisTable"
			:id="'analysis-table'"
			:class="'text-center rounded'"
			:items="items"
			:fields="fields"
			:empty-text="$t('조회된 결과가 없습니다.')"
		>
			<template #empty="scope">
				<h4>{{ scope.emptyText }}</h4>
			</template>

			<template #cell()="data">
				<div v-if="data.field.key == 'regDtTag'">{{ analysisLib.localeDateTime(data.value, locale, timeDsvn) }}</div>
				<div v-else :style="{ textAlign: 'center' }">{{ data.value }}</div>
			</template>
		</b-table>
	</div>
</template>

<style scoped>
	.b-table-sticky-header {
		max-height: 760px;
	}
</style>

<script>
	// import detailCard from "./GridCard.vue";
	import XLSX from "xlsx";
	import AnalysisLib from "@src/views/analysis/AnalysisLib";
	import * as popupMessages from "@src/consts/popupMessageConsts";

	export default {
		components: {},
		props: ["dataList", "propList", "searchCond", "timeDsvn", "excelTitle"],
		data() {
			return {
				fields: null,
				items: null,
				emptyText: null,
				selectedRow: [],
				analysisLib: AnalysisLib,
			};
		},
		computed: {
			locale() {
				return this.$store.state.locale;
			},
		},
		watch: {
			propList() {
				this.setFields();
			},
			dataList() {
				this.selectedRow._showDetails = !this.selectedRow._showDetails;
				this.setItems();
			},
		},
		created() {
			this.setFields();
			this.setItems();
		},
		mounted() {},
		methods: {
			setFields() {
				this.fields = [{ key: "regDtTag", label: global.xe.$t("기준 시간") }];

				this.propList.forEach((data) => {
					this.fields.push({
						key: data.equipPropCode,
						label: `${data.equipPropName} (${data.unitSmallCodeName})`,
						code: data.unitSmallCode,
					});
				});
			},
			setItems() {
				this.items = this.dataList;
			},
			onReset() {
				this.$refs.detail.onReset();
			},
			onExcelDownload() {

				// var excelName = this.searchCond.timeForm + this.searchCond.timeDsvn + " EHP 실내기";
				var excelName = `${this.excelTitle} (${this.searchCond.timeDsvn})-${this.searchCond.timeForm}.xls`

				this.alertQuestion(`${excelName} \r\n ${popupMessages.COMMON_EXCEL_DOWNLOAD_POPUP_MESSAGE}`).then((result) => {
					if (!result.value) return;

					var excelData = XLSX.utils.table_to_sheet(document.getElementById("analysis-table")); // table id를 넣어주면된다
					var workBook = XLSX.utils.book_new(); // 새 시트 생성

					XLSX.utils.book_append_sheet(workBook, excelData, excelName); // 시트 명명, 데이터 지정
					XLSX.writeFile(workBook, excelName + ".xlsx"); // 엑셀파일 만듬
				});
			},
		},
	};
</script>
